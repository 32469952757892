import React, { useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { pageTitleSidemenu } from '../../../utils';
import { useAuth } from '../../../hooks';
import { logout } from '../../../services';
import { Icons } from '../../../assets/icons';
import * as s from './styled-header';

const Header = ({ pathName }) => {
	const location = useLocation();
	const pathname = location?.pathname;
	const { currentUser } = useAuth();
	const [menu, setMenu] = useState(false);

	const isNaN = (value) => Number.isNaN(value);

	const haveBreadcrumbs = () => {
		const aposBarra = pathname.split('/').pop();
		const contemId = aposBarra.substring(0, 3) === 're_';
		if (
			(pathname.includes(
				'/transacoes' || '/recebedores' || '/antecipacoes' || 'links-pagamento'
			) &&
				!isNaN(Number(aposBarra))) ||
			contemId ||
			(pathname.includes('/recebedores') && aposBarra !== 'recebedores') ||
			(pathname.includes('/contratante') &&
				!pathname.includes('/contratantes')) ||
			aposBarra === 'antecipacoes' ||
			(pathname.includes('links-pagamento') && aposBarra !== 'links-pagamento')
		)
			return true;
		return false;
	};

	const renderCurrentIcon = () => {
		if (pathname === '/resumo') {
			return <Icons.Resumo />;
		}
		if (pathname === '/extrato' || pathname === '/antecipacoes') {
			return <Icons.Extrato />;
		}
		if (pathname.includes('/transacoes')) {
			return <Icons.Transacoes />;
		}
		if (pathname === '/pagamento') {
			return <Icons.Links />;
		}
		if (pathname === '/recebedores' || pathname.includes('/recebedor')) {
			return <Icons.Recebedores />;
		}
		if (pathname === '/taxas') {
			return <Icons.Taxas />;
		}
		if (pathname.includes('/contratante')) {
			return <Icons.Contratantes />;
		}
		if (pathname.includes('/acessos')) {
			return <Icons.Acessos />;
		}
		if (pathname === '/configuracoes') {
			return <Icons.Configuracoes />;
		}
		if (pathname === '/clientes') {
			return <Icons.Clientes />;
		}
		if (pathname === '/logs') {
			return <Icons.Logs />;
		}
		if (
			pathname === '/links-pagamento' ||
			pathname.includes('links-pagamento')
		) {
			return <Icons.Links />;
		}
	};

	const renderLastPathname = (path) => {
		const aposBarra = path.split('/').pop();
		const contemId = aposBarra.substring(0, 3) === 're_';
		if (aposBarra === 'adicionar-contratante') {
			return 'Novo Contratante';
		}
		if (pathname.includes('/recebedores')) {
			if (contemId) return 'Detalhes do Recebedor';
			if (aposBarra === 'adicionar-recebedor') return 'Adicionar Recebedor';
		}
		if (pathname.includes('/links-pagamento')) {
			if (contemId) return 'Detalhes do link de pagamento';
		}
		if (path === '/antecipacoes') return 'Antecipações';

		if (typeof Number(isNaN(aposBarra)) === 'number') {
			if (path.includes('/estorno')) return 'Estornar';
			if (path.includes('/transacoes')) return 'Detalhes da Transação';
			if (path.includes('/contratante')) return 'Detalhes do Contratante';
			if (path.includes('/links-pagamento'))
				return 'Detalhes do link de pagamento';
		}
	};

	return (
		<s.Header menu={menu} pathName={pathName}>
			<s.LeftHeader reverseColor={haveBreadcrumbs()}>
				{renderCurrentIcon()}
				<h1>{pageTitleSidemenu(pathname)}</h1>
				{haveBreadcrumbs() && (
					<>
						<Icons.Chevron />
						<h2>{renderLastPathname(pathname)}</h2>
					</>
				)}
			</s.LeftHeader>
			<s.RightHeader menu={menu}>
				<button type="button" onClick={() => setMenu(!menu)}>
					<div className="Menu">
						<Icons.Perfil style={{ width: '1rem', height: '1.625rem' }} />
						<span>{currentUser?.name}</span>
						{menu ? <FiChevronUp /> : <FiChevronDown />}
						{menu && <DropdownMenu menu={menu} />}
					</div>
				</button>
			</s.RightHeader>
		</s.Header>
	);
};

const DropdownMenu = () => {
	const { currentUser, setCurrentUser } = useAuth();
	const history = useHistory();

	const handleLogout = async () => {
		try {
			logout().then(() => {
				localStorage.clear('user');
				setCurrentUser({});
				history.push('/login');
			});
		} catch {
			toast.error('Erro ao sair.');
		}
	};

	return (
		<s.BackgroundClose>
			<s.DropdownMenu>
				<s.MenuHeader>
					<h4>{currentUser?.name}</h4>
					<p>{currentUser?.email}</p>
				</s.MenuHeader>
				<s.MenuDivider />
				<s.MenuFooter>
					<s.ButtonLogout type="button" onClick={() => handleLogout()}>
						Sair
					</s.ButtonLogout>
				</s.MenuFooter>
			</s.DropdownMenu>
		</s.BackgroundClose>
	);
};

export default Header;
