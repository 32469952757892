import React, { useState, useEffect } from 'react';
import { utils } from '@amir04lm26/react-modern-calendar-date-picker';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Tabela from './tabela/tabela';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { colors, Icons } from '../../../../assets';
import {
	FiltroCalendario,
	FloaterReact,
	FloaterOverlay,
	LoadingRectangle,
	Pagination,
	SelectReact,
	ButtonCustom,
	CustomCheckboxExtract,
} from '../../../../components';
import {
	formatarParaReais,
	padStart,
	adicionarDiasCalendario,
	optionsItemsPage,
} from '../../../../utils';
import { useAuth } from '../../../../contexts';
import { DetalhesExtratos } from '../../../molecules/gerador-pdf-extratos';

import * as s from './styled-infos-table';

const InfosTable = ({
	loadingExtrato,
	intervaloInicial,
	selectedDayRange,
	setSelectedDayRange,
	extrato,
	extratoInfos,
	buscarExtrato,
	pagination,
	setPagination,
	selected,
	setSelected,
	tableData,
	setMarketType,
}) => {
	const { data } = extrato;

	const { currentUser } = useAuth();

	const beiraRioContractor =
		currentUser?.contractorId === 'KV9XYmke83Z8Pcmuvbec';

	const { discountsValue, grossValue, netValue, taxValue } = extratoInfos;

	const [columnEntry, setColumnEntry] = useState(() => {
		if (sessionStorage.getItem('columnEntryExtract')) {
			return JSON.parse(sessionStorage.getItem('columnEntryExtract'));
		}
		return true;
	});

	const [columnOut, setColumnOut] = useState(() => {
		if (sessionStorage.getItem('columnOutExtract')) {
			return JSON.parse(sessionStorage.getItem('columnOutExtract'));
		}
		return true;
	});

	const itemsPerPageItems = optionsItemsPage(beiraRioContractor);

	const [itemsPage, setItemsPage] = useState(() => {
		if (beiraRioContractor) return itemsPerPageItems[2];
		if (
			sessionStorage.getItem('itemsPerPageExtrato') &&
			sessionStorage.getItem('itemsPerPageExtrato') !== ''
		) {
			return JSON?.parse(sessionStorage?.getItem('itemsPerPageExtrato'));
		}
		sessionStorage.setItem(
			'itemsPerPageExtrato',
			JSON.stringify(itemsPerPageItems[0])
		);
		return itemsPerPageItems[0];
	});

	const cards = [
		{
			type: 'green',
			titulo: 'Total de entradas',
			id: 'total-entradas',
			content: 'Todos os lançamentos de vendas brutas',
			valor: grossValue,
			color: colors.green,
		},
		{
			type: 'red',
			titulo: 'Total de saídas',
			id: 'total-saidas',
			content: 'Todos os lançamentos de transferências, chargebacks e estornos',
			valor: discountsValue,
			color: colors.primaryRed,
		},
		{
			type: 'red',
			titulo: 'Taxas PayOS',
			id: 'taxas-pagarme',
			content:
				'Todos os descontos de tarifas de cartão, gateway, boleto e TEDs',
			valor: taxValue,
			color: colors.primaryRed,
		},
		{
			type: 'purple',
			titulo: 'Total líquido',
			id: 'total-liquido',
			content:
				'Todos os lançamentos de vendas descontando as taxas, chargebacks e estornos',
			valor: netValue,
			color: colors.violet,
		},
	];

	const [dataInvalida, setDataInvalida] = useState({
		inicio: false,
		final: false,
	});

	const desabilitado =
		loadingExtrato ||
		dataInvalida?.inicio ||
		dataInvalida?.final ||
		extrato?.length < 1 ||
		!extrato;

	const exportarExtrato = (csv) => {
		const botaoExportar = document.getElementById(
			`baixar-extrato-${csv ? 'csv' : 'xlsx'}`
		);
		if (botaoExportar) botaoExportar.click();
	};

	const exportarPdf = () => {
		return (
			data !== undefined && (
				<PDFDownloadLink
					document={
						<DetalhesExtratos
							loading={loadingExtrato}
							data={data}
							columnEntry={columnEntry}
							columnOut={columnOut}
						/>
					}
					fileName="extratos.pdf"
				>
					<button type="button">PDF</button>
				</PDFDownloadLink>
			)
		);
	};

	const opcoesExportacao = () => (
		<s.Options>
			<ul>
				<li>
					<button
						type="button"
						onClick={() => exportarExtrato(true)}
						disabled={desabilitado}
					>
						CSV
					</button>
				</li>
				<li>
					<button
						type="button"
						onClick={() => exportarExtrato(false)}
						disabled={desabilitado}
					>
						Excel
					</button>
				</li>
				{!loadingExtrato && <div className="button">{exportarPdf()}</div>}
				<li />
			</ul>
		</s.Options>
	);

	const valorEstilizado = (valor, fill) => {
		if (valor) {
			const negativo = valor < 0;
			let valorVisual = valor;

			if (negativo) {
				valorVisual = -1 * valor;
			}

			const valorFormatado = formatarParaReais(valorVisual / 100);

			return (
				<s.Sinal negativo={negativo} neutro={valor === 0}>
					{negativo ? <Icons.Minus fill={fill} /> : <Icons.Plus fill={fill} />}
					<span className="cifrao"> R$ </span>
					<span className="valor">{valorFormatado}</span>
				</s.Sinal>
			);
		}

		return (
			<s.Sinal neutro>
				<span className="cifrao">R$</span>
				<span className="valor">0,00</span>
			</s.Sinal>
		);
	};

	const dataFormatada = () => {
		if (selectedDayRange?.from && selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			const final = selectedDayRange.to;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(final.day)}/${padStart(final.month)}/${final.year}`,
			};
		}

		if (selectedDayRange?.day) {
			const inicio = selectedDayRange;

			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		if (selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		return null;
	};

	const dataSelecionada = () => {
		const msg = dataFormatada();

		if (msg === null) return 'Selecione o período de filtragem';
		if (msg.to) return `${msg.from} - ${msg.to}`;
		return `${msg.from}`;
	};

	const positionCalendar = `translate3d(350px, 0px, 0)`;

	const handleCleanFilters = () => {
		sessionStorage.setItem('paginationExtrato', JSON.stringify(1));
		sessionStorage.removeItem('totalPagesExtrato');
		sessionStorage.removeItem('extratoInfos');
		setPagination({ page: 1, totalPages: 0 });
		sessionStorage.removeItem('anteriorDataInicial');
		sessionStorage.removeItem('anteriorDataFinal');
		sessionStorage.removeItem('receberDataInicial');
		sessionStorage.removeItem('receberDataFinal');
		sessionStorage.removeItem('columnEntryExtract');
		sessionStorage.removeItem('columnOutExtract');
		if (selected === 'receber') {
			setSelectedDayRange(adicionarDiasCalendario(7));
		} else {
			setSelectedDayRange(intervaloInicial());
		}
		setMarketType('');
		setColumnEntry(true);
		setColumnOut(true);
		buscarExtrato(1, itemsPage.value);
	};

	useEffect(() => {
		const paginationExtrato = sessionStorage.getItem('paginationExtrato');
		if (tableData) {
			if (paginationExtrato <= pagination?.totalPages) {
				buscarExtrato(pagination?.page, itemsPage.value);
			} else {
				sessionStorage.setItem('paginationExtrato', JSON.stringify(1));
				buscarExtrato(1, itemsPage.value);
			}
		}
	}, [itemsPage, selected]);

	return (
		<s.Container>
			<s.ButtonsTabs>
				<s.Button
					className={selected === 'anterior' ? 'selected anterior' : ''}
					onClick={() => {
						setSelected('anterior');
						sessionStorage.setItem('selectPeriodo', 'anterior');
						setPagination({ page: 1, totalPages: 0 });
						setSelectedDayRange(intervaloInicial());
					}}
					disabled={loadingExtrato}
				>
					Anteriores
				</s.Button>
				<s.Button
					className={selected === 'receber' ? 'selected receber' : ''}
					onClick={() => {
						setSelected('receber');
						sessionStorage.setItem('selectPeriodo', 'receber');
						setPagination({ page: 1, totalPages: 0 });
						setSelectedDayRange(adicionarDiasCalendario(7));
					}}
					disabled={loadingExtrato}
				>
					A receber
				</s.Button>
			</s.ButtonsTabs>
			<s.ContainerContent header>
				<s.FilterTab dataInvalida={dataInvalida}>
					<FiltroCalendario
						disabled={loadingExtrato}
						formatedRangeDate={dataFormatada}
						selectedDayRange={selectedDayRange}
						setSelectedDayRange={setSelectedDayRange}
						maximumDate={selected === 'anterior' ? utils().getToday() : null}
						minimumDate={selected === 'receber' ? utils().getToday() : null}
						dataInvalida={dataInvalida}
						setDataInvalida={setDataInvalida}
						selected={selected}
						backgroundColor={colors.darkGray}
						transform={positionCalendar}
						hideArrow
					/>
					{dataInvalida.inicio || dataInvalida.final ? (
						<span>A data informada é inválida</span>
					) : (
						<br />
					)}
				</s.FilterTab>
				<s.ButtonGroup>
					<ButtonCustom
						width="200px"
						outlined
						onClick={() => {
							handleCleanFilters();
						}}
						disabled={
							loadingExtrato || dataInvalida?.inicio || dataInvalida?.final
						}
					>
						Limpar
					</ButtonCustom>
					<ButtonCustom
						width="200px"
						onClick={() => {
							sessionStorage.setItem('paginationExtrato', JSON.stringify(1));
							sessionStorage.removeItem('totalPagesExtrato');
							sessionStorage.removeItem('extratoInfos');
							setPagination({ page: 1, totalPages: 0 });
							buscarExtrato(1, itemsPage.value);
						}}
						disabled={
							loadingExtrato || dataInvalida?.inicio || dataInvalida?.final
						}
					>
						Filtrar
					</ButtonCustom>
				</s.ButtonGroup>
			</s.ContainerContent>
			<s.ContainerContent>
				<s.CardsDiv>
					{cards.map((card) => (
						<s.Card key={card.id} color={card.type}>
							<s.Content>
								<s.Row>
									<h3>{card.titulo}</h3>
									<FloaterReact
										hover
										id={card.id}
										content={card.content}
										width="200px"
									>
										<Icons.Question />
									</FloaterReact>
								</s.Row>

								{loadingExtrato ? (
									<LoadingRectangle />
								) : (
									valorEstilizado(card.valor, card.color)
								)}
							</s.Content>
						</s.Card>
					))}

					<s.Row headerTable>
						<s.Data>
							Período
							<br /> {dataSelecionada()}
						</s.Data>
						<s.CheckboxTable>
							<h3>Exibir colunas</h3>
							<div>
								<CustomCheckboxExtract
									id="MI"
									text="Entradas"
									value={columnEntry}
									onChange={() => {
										setColumnEntry((prev) => !prev);
										sessionStorage.setItem('columnEntryExtract', !columnEntry);
									}}
									checked={columnEntry}
								/>
								<CustomCheckboxExtract
									id="ME"
									text="Saídas"
									value={columnOut}
									onChange={() => {
										setColumnOut((prev) => !prev);

										sessionStorage.setItem('columnOutExtract', !columnOut);
									}}
									checked={columnOut}
								/>
							</div>
						</s.CheckboxTable>

						<s.Right>
							<FloaterOverlay
								placement="bottom"
								background={colors.white}
								padding="0"
								content={opcoesExportacao()}
								disabled={desabilitado}
							/>
							<SelectReact
								options={itemsPerPageItems}
								value={itemsPage}
								onChange={(opt) => {
									setItemsPage(opt);
									setPagination({ ...pagination, page: 1 });
									sessionStorage.setItem(
										'itemsPerPageExtrato',
										JSON.stringify(opt)
									);
									sessionStorage.setItem(
										'paginationExtrato',
										JSON.stringify(1)
									);
								}}
								readOnly={desabilitado}
								outlined
							/>
							<s.Pagination>
								{pagination?.totalPages > 1 && (
									<Pagination
										paginaAtual={pagination?.page - 1}
										totalPaginas={pagination?.totalPages}
										onClick={(pagina) => {
											buscarExtrato(pagina.selected + 1, itemsPage.value);
											sessionStorage.setItem(
												'paginationExtrato',
												JSON.stringify(Number(pagina.selected + 1))
											);
											sessionStorage.setItem(
												'totalPagesExtrato',
												JSON.stringify(Number(pagination.totalPages))
											);
										}}
										disabled={desabilitado}
									/>
								)}
							</s.Pagination>
						</s.Right>
					</s.Row>

					<Tabela
						columnEntry={columnEntry}
						columnOut={columnOut}
						dados={tableData}
						loadingExtrato={loadingExtrato}
					/>

					<s.RowFooter>
						{pagination?.totalPages > 1 && (
							<Pagination
								paginaAtual={pagination?.page - 1}
								totalPaginas={pagination?.totalPages}
								onClick={(pagina) => {
									buscarExtrato(pagina.selected + 1, itemsPage.value);
									sessionStorage.setItem(
										'paginationExtrato',
										JSON.stringify(Number(pagina.selected + 1))
									);
								}}
								disabled={desabilitado}
							/>
						)}
					</s.RowFooter>
				</s.CardsDiv>
			</s.ContainerContent>
		</s.Container>
	);
};

export default InfosTable;
