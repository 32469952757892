import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavLink from './nav-link/nav-link';
import MenuHeader from './menu-header/menu-header';
import { useAuth } from '../../../hooks';
import { pageTitleSidemenu } from '../../../utils';
import { Icons } from '../../../assets';

import * as s from './styled-sidemenu';

const Sidemenu = ({ isSideMenuOpen, setIsSideMenuOpen }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;
	const isBrioContractor = currentUser?.contractorId === 'KV9XYmke83Z8Pcmuvbec';
	const canSeeClientsMenu =
		userType === 'administrator' ||
		(isBrioContractor && userType !== 'subcontractor');
	const location = useLocation();
	const pathname = location.pathname;
	const [selected, setSelected] = useState(pageTitleSidemenu(pathname));

	return (
		<aside>
			<div>
				<s.Sidemenu
					isSideMenuOpen={isSideMenuOpen}
					className="custom-scroll-dark"
				>
					<MenuHeader
						isSideMenuOpen={isSideMenuOpen}
						setIsSideMenuOpen={setIsSideMenuOpen}
					/>
					<s.NavItem isSideMenuOpen={isSideMenuOpen}>
						<NavLink
							to="/resumo"
							selected={selected === 'Resumo'}
							onClick={() => setSelected('Resumo')}
							span="Resumo"
							isSideMenuOpen={isSideMenuOpen}
						>
							<Icons.Resumo />
						</NavLink>
						<NavLink
							to="/extrato"
							selected={selected === 'Extrato' || pathname === '/antecipacoes'}
							onClick={() => setSelected('Extrato')}
							span="Extrato"
							isSideMenuOpen={isSideMenuOpen}
						>
							<Icons.Extrato />
						</NavLink>
						<NavLink
							to="/transacoes"
							selected={selected === 'Transações'}
							onClick={() => setSelected('Transações')}
							span="Transações"
							isSideMenuOpen={isSideMenuOpen}
						>
							<Icons.Transacoes />
						</NavLink>
						{userType !== 'subcontractor' && (
							<>
								<NavLink
									to="/recebedores"
									selected={selected === 'Recebedores'}
									onClick={() => setSelected('Recebedores')}
									span="Recebedores"
									isSideMenuOpen={isSideMenuOpen}
								>
									<Icons.Recebedores />
								</NavLink>
								<NavLink
									to="/links-pagamento"
									selected={selected === 'Links de Pagamento'}
									onClick={() => setSelected('Links de Pagamento')}
									span="Links de Pagamento"
									isSideMenuOpen={isSideMenuOpen}
								>
									<Icons.Links />
								</NavLink>
							</>
						)}
						{/* <NavLink
							to="/taxas"
							selected={selected === 'Taxas'}
							onClick={() => setSelected('Taxas')}
							span="Taxas"
							isSideMenuOpen={isSideMenuOpen}
						>
							<Icons.Taxas />
						</NavLink> */}
						{/* }
						{userType === 'administrator' && (
							<NavLink
								to="/contratantes"
								selected={selected === 'Contratantes'}
								onClick={() => setSelected('Contratantes')}
								span="Contratantes"
								isSideMenuOpen={isSideMenuOpen}
							>
								<Icons.Contratantes />
							</NavLink>
						)}
						{userType === 'administrator' && (
							<NavLink
								to="/acessos"
								selected={selected === 'Acessos'}
								onClick={() => setSelected('Acessos')}
								span="Acessos"
								isSideMenuOpen={isSideMenuOpen}
							>
								<Icons.Acessos />
							</NavLink>
						)} */}
						{canSeeClientsMenu ? (
							<NavLink
								to="/clientes"
								selected={selected === 'clientes'}
								onClick={() => setSelected('clientes')}
								span="Clientes"
								isSideMenuOpen={isSideMenuOpen}
							>
								<Icons.Clientes />
							</NavLink>
						) : null}

						{/*
						<NavLink
							to="/logs"
							selected={selected === 'logs'}
							onClick={() => setSelected('logs')}
							span="Logs"
							isSideMenuOpen={isSideMenuOpen}
						>
							<Icons.Logs />
						</NavLink>
						*/}
						{/*
						<NavLink
							to="/configuracoes"
							selected={selected === 'Configurações'}
							onClick={() => setSelected('Configurações')}
							span="Configurações"
							isSideMenuOpen={isSideMenuOpen}
						>
							<Icons.Configuracoes />
						</NavLink>
						*/}
					</s.NavItem>
				</s.Sidemenu>
			</div>
		</aside>
	);
};
export default Sidemenu;
