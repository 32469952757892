import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { add, parse, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import toast from 'react-hot-toast';
import {
	getLimitesAntecipacao,
	postCriarAntecipacao,
	deleteAntecipacao,
} from '../services';
import { useAuth } from '../hooks';
import {
	valorMonetario,
	padStart,
	dataInicialLimitesAntecipacao,
} from '../utils';

const AnticipationContext = createContext();

export function AnticipationProvider({ children }) {
	const history = useHistory();
	const { currentUser } = useAuth();
	const { recipientId } = useParams();
	const idRecebedor = recipientId || currentUser?.recipientId;

	const [step, setStep] = useState(1);
	const [primeiraRequisicao, setPrimeiraRequisicao] = useState(true);
	const [dataSelecionada, setDataSelecionada] = useState(
		dataInicialLimitesAntecipacao()
	);
	const [dataInvalida, setDataInvalida] = useState(false);
	const [erroData, setErroData] = useState(false);
	const [limites, setLimites] = useState({});
	const [valores, setValores] = useState({});
	const [loadingValores, setLoadingValores] = useState(false);
	const [valorDesejado, setValorDesejado] = useState('');
	const [idAntecipacao, setIdAntecipacao] = useState('');
	const [tipoAntecipacao, setTipoAntecipacao] = useState('conta');
	const [ordemAntecipacao, setOrdemAntecipacao] = useState('start');
	const taxaTransf = tipoAntecipacao === 'conta' ? -290 : 0;
	const [timerConfirmarAntecipacao, setTimerConfirmarAntecipacao] =
		useState(false);

	const minimo = limites?.minimo || 0;
	const maximo = limites?.maximo || 0;

	function enableDisableClicks() {
		const html = document.getElementsByTagName('body')[0].style;
		const pointerEvents = html?.pointerEvents;

		if (pointerEvents === '' || pointerEvents === 'auto')
			html.pointerEvents = 'none';
		else html.pointerEvents = '';
	}

	const dataFormatadaAntecipacao = () => {
		if (dataSelecionada?.day) {
			const data = dataSelecionada;
			return `${padStart(data.day)}/${padStart(data.month)}/${data.year}`;
		}

		return null;
	};

	function dataValida() {
		if (dataFormatadaAntecipacao()) {
			const formatDate = (date) =>
				parse(date, 'P', new Date(), { locale: ptBR });
			const dateSelected = formatDate(dataFormatadaAntecipacao());
			const isValidDate = isValid(dateSelected);

			return isValidDate;
		}

		return '';
	}

	const dataFormatadaRequisicao = () => {
		let data = dataFormatadaAntecipacao();
		data = data.split('/');
		data = `${data[1]}-${data[0]}-${data[2]}`;
		data = new Date(data).getTime();
		return data;
	};

	function buscarLimites(data, prazo) {
		getLimitesAntecipacao(idRecebedor, data, prazo)
			.then((resp) => {
				if (erroData) setErroData(false);
				if (dataInvalida) setDataInvalida(false);

				setLimites({
					minimo: resp.minimum.amount / 100,
					maximo: resp.maximum.amount / 100,
				});

				if (primeiraRequisicao) {
					setValorDesejado(valorMonetario(resp.maximum.amount));
				}
			})
			.catch(({ response: { data: dados } }) => {
				if (dados?.extra?.message) setErroData(dados.extra.message);
				else if (dados?.message) setErroData(dados.message);
			})
			.finally(() => {
				if (primeiraRequisicao) setPrimeiraRequisicao(false);
			});
	}

	function deletarAntecipacao(redirecionar) {
		deleteAntecipacao(idRecebedor, idAntecipacao)
			.then(() => {
				localStorage.removeItem('anticipation');
				if (redirecionar) history.push('/extrato');
				else {
					setIdAntecipacao('');
				}
			})
			.catch(() =>
				toast.error('Houve um erro ao deletar a antecipação. Tente novamente')
			);
	}

	const timeoutConfirmarAntecipacao = () => {
		let tempo = new Date();
		tempo = add(new Date(), { minutes: 5 });
		tempo = `${tempo.getHours()}:${padStart(tempo.getMinutes())} horas`;

		return tempo;
	};

	function criarAntecipacao(body, handleClicks) {
		postCriarAntecipacao(body)
			.then((resp) => {
				// tempo de 5min
				setTimerConfirmarAntecipacao(300);
				localStorage.setItem(
					'anticipation',
					JSON.stringify({
						anticipationId: resp.id,
						anticipationTimer: timeoutConfirmarAntecipacao(),
					})
				);
				const valorBruto = resp.amount - resp.fee;
				const taxaAntecipacao = -1 * resp.anticipation_fee;

				setValores({
					valorBruto,
					taxaAntecipacao,
					valorAntecipacao: valorBruto + taxaAntecipacao,
					tipoAntecipacaoSelecionado: tipoAntecipacao,
					ordemAntecipacaoSelecionado: ordemAntecipacao,
					valorDesejado,
				});
				setIdAntecipacao(resp.id);
			})
			.catch(() => toast.error('Erro ao criar registro de antecipação'))
			.finally(() => {
				if (handleClicks) enableDisableClicks('enabled');
				setLoadingValores(false);
			});
	}
	function calcularAntecipacao(put = false, valorMaximo, handleClicks = false) {
		setLoadingValores(true);
		if (valorMaximo) {
			setValorDesejado(valorMonetario(maximo * 100));
		}

		const body = {
			build: true,
			automatic_transfer: tipoAntecipacao === 'conta',
			payment_date: dataFormatadaRequisicao(),
			recipientId: idRecebedor,
			requested_amount: valorMaximo
				? maximo * 100
				: Number(valorDesejado.replace('.', '').replace(',', '.')) * 100,
			timeframe: ordemAntecipacao,
		};

		if (put) {
			setTimerConfirmarAntecipacao(false);
			localStorage.removeItem('anticipationTimer');

			if (idAntecipacao) deletarAntecipacao(false);

			setTimeout(() => {
				criarAntecipacao(body, handleClicks);
			}, 1000);
		} else criarAntecipacao(body, handleClicks);
	}

	useEffect(() => {
		buscarLimites(dataInicialLimitesAntecipacao(true));
	}, []);

	useEffect(() => {
		if (!primeiraRequisicao && minimo > 0) calcularAntecipacao(false, 'max');
	}, [primeiraRequisicao]);

	useEffect(() => {
		if (limites && !primeiraRequisicao && minimo > 0) {
			enableDisableClicks('disabled');
			calcularAntecipacao(true, 'max', true);
		}
	}, [limites]);

	useEffect(() => {
		if (!primeiraRequisicao && dataValida()) {
			if (idAntecipacao) {
				deletarAntecipacao(false);

				setTimeout(() => {
					buscarLimites(dataFormatadaRequisicao(), ordemAntecipacao);
				}, 1000);
			} else buscarLimites(dataFormatadaRequisicao(), ordemAntecipacao);
		}
	}, [dataSelecionada]);

	useEffect(() => {
		if (timerConfirmarAntecipacao > 0) {
			setTimeout(() => {
				setTimerConfirmarAntecipacao((contador) => contador - 1);
			}, 1000);
		} else if (timerConfirmarAntecipacao === 0) {
			localStorage.removeItem('anticipation');
		}
	}, [timerConfirmarAntecipacao]);

	const value = {
		idRecebedor,
		step,
		setStep,
		dataSelecionada,
		setDataSelecionada,
		dataInvalida,
		setDataInvalida,
		dataFormatadaAntecipacao,
		dataFormatadaRequisicao,
		erroData,
		limites,
		minimo,
		maximo,
		buscarLimites,
		primeiraRequisicao,
		valores,
		loadingValores,
		valorDesejado,
		setValorDesejado,
		idAntecipacao,
		tipoAntecipacao,
		setTipoAntecipacao,
		ordemAntecipacao,
		setOrdemAntecipacao,
		taxaTransf,
		timerConfirmarAntecipacao,
		setTimerConfirmarAntecipacao,
		calcularAntecipacao,
		deletarAntecipacao,
		enableDisableClicks,
	};

	return (
		<AnticipationContext.Provider value={value}>
			{children}
		</AnticipationContext.Provider>
	);
}

export function useAntecipacao() {
	return useContext(AnticipationContext);
}
