import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useAuth } from '../../../../contexts';
import { postEstorno } from '../../../../services';
import { ButtonCustom } from '../../../atoms';
import { formatarParaReais, formatCardNumber } from '../../../../utils';
import { Installments } from '../../../../pages/transaction-details/components/card-header/styled-card-header';
import { LoadingRectangle, ModalCancelarEstorno } from '../../../molecules';

import * as s from './styled-confirmacao';

const ConfirmacaoEstorno = ({
	step,
	setStep,
	valorEstorno,
	chosedRecebedor,
	dados,
	loading,
	setAlert,
	setAlertMessage,
}) => {
	const { transactionId } = useParams();
	const { recipientId } = useAuth().currentUser;

	const {
		customer,
		cardFirstDigits,
		cardLastDigits,
		authorizedValue,
		cardBrand,
		installments,
	} = dados;

	const nomeCliente = customer?.name;
	const emailCliente = customer?.email;
	const bandeira = cardBrand;
	const firstDigitsCard = cardFirstDigits;
	const lastDigitsCard = cardLastDigits;
	const valor = authorizedValue;

	const history = useHistory();

	const [openModal, setOpenModal] = useState(false);

	const voltar = () => {
		history.push(`/transacoes/${transactionId}`);
	};

	const renderInstallments = () => {
		if (installments === 1) {
			return 'À vista';
		}
		return <Installments>Parcelado {installments}x</Installments>;
	};

	return (
		<s.Container>
			<s.Content>
				<s.Column>
					<h2>Dados do Estorno:</h2>
					<br />
					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Nome do Cliente:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? `${nomeCliente}` : <LoadingRectangle />}
							</s.P>
						</s.Label>
						<s.Label>
							<s.TituloParagrafo>Email:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? `${emailCliente}` : <LoadingRectangle />}
							</s.P>
						</s.Label>
					</s.Linegroup>
				</s.Column>
				<s.Column>
					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Número do Cartão:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? (
									`${formatCardNumber(firstDigitsCard, lastDigitsCard)}`
								) : (
									<LoadingRectangle />
								)}
							</s.P>
						</s.Label>
						<s.Label>
							<s.TituloParagrafo>Bandeira:</s.TituloParagrafo>
							<s.P>{!loading ? `${bandeira}` : <LoadingRectangle />}</s.P>
						</s.Label>
					</s.Linegroup>
				</s.Column>
				<s.Column>
					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Valor da transação:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? (
									`R$${formatarParaReais(valor / 100)}`
								) : (
									<LoadingRectangle />
								)}
							</s.P>
						</s.Label>
						<s.Label>
							<s.TituloParagrafo>Parcelas:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? renderInstallments() : <LoadingRectangle />}
							</s.P>
						</s.Label>

						{chosedRecebedor !== 'Manter regras de divisão' && (
							<s.Label>
								<s.TituloParagrafo>Recebedor:</s.TituloParagrafo>
								<s.P>
									<br />
									{!loading ? `${chosedRecebedor}` : <LoadingRectangle />}
								</s.P>
							</s.Label>
						)}
					</s.Linegroup>
					<s.Linediv />
				</s.Column>
				<s.Column>
					<s.TituloParagrafo>Valor do Estorno(R$):</s.TituloParagrafo>
					{!loading ? (
						<h2 className="valorEstornoBig">{`R$ ${formatarParaReais(
							Number(valorEstorno) / 100
						)}`}</h2>
					) : (
						<LoadingRectangle />
					)}
				</s.Column>
				<s.Linegroup
					style={{
						justifyContent: 'space-between',
						marginBottom: '40px',
						padding: '0 1.25rem',
					}}
				>
					<ButtonCustom
						theme="green"
						outlined
						style={{ flex: '1' }}
						onClick={() => setOpenModal(true)}
					>
						Cancelar
					</ButtonCustom>
					<div className="groupbtn">
						<ButtonCustom
							theme="green"
							outlined
							onClick={() => {
								setStep(step - 1);
							}}
						>
							Voltar
						</ButtonCustom>
						<ButtonCustom
							disabled={loading}
							theme="green"
							onClick={() => {
								setStep(step + 1);
								postEstorno(transactionId, valorEstorno, recipientId)
									.then(() => {
										setAlert('success');
										setAlertMessage('Estorno realizado com sucesso!');
									})
									.catch((resp) => {
										setAlert('error');
										const errorMessage = resp?.message;
										setAlertMessage(errorMessage);
									})
									.finally(() => false);
							}}
						>
							Continuar
						</ButtonCustom>
					</div>
				</s.Linegroup>
			</s.Content>

			<ModalCancelarEstorno
				isOpen={openModal}
				cancel={voltar}
				closeModal={() => setOpenModal(false)}
			/>
		</s.Container>
	);
};

export default ConfirmacaoEstorno;
