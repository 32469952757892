import React from 'react';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import {
	Antecipacoes,
	Client,
	Dashboard,
	Estorno,
	ForgotPassword,
	Invite,
	Login,
	NewPassword,
	NewPaymentLink,
	NewRecipient,
	NotFound,
	Recipients,
	RecipientDetails,
	Signup,
	PaymentDetails,
	PaymentLinks,
	Transactions,
	TransactionsDetails,
} from '../pages';
import { useAuth } from '../hooks';
import {
	NewPaymentLinksProvider,
	RecipientsProvider,
	RecipientInfoProvider,
	AnticipationProvider,
} from '../contexts';
import {
	DeslogadoRoute,
	LogadoRoute,
	AdminRoute,
	ContratanteRoute,
	SubContractorRoute,
	AdminRouteWithContext,
} from './route-types';

const Routes = ({ setNotFound, location }) => {

	const { currentUser } = useAuth();
	const userType = currentUser?.role;

		return (
			<Switch>
				{/* PÚBLICO - DESLOGADO */}
				<DeslogadoRoute exact path="/login" component={Login} />
	
				<DeslogadoRoute exact path="/cadastrar" component={Signup} />
	
				<DeslogadoRoute exact path="/esqueci-senha" component={ForgotPassword} />
	
				<DeslogadoRoute exact path="/redefinir-senha" component={NewPassword} />
	
				<DeslogadoRoute exact path="/invite" component={Invite} />
	
				{/* PÚBLICO - LOGADO */}
	
				<LogadoRoute exact path="/resumo" component={Dashboard} />
	
				<LogadoRoute exact path="/transacoes" component={Transactions} />
	
				<LogadoRoute
					path="/transacoes/estorno/:transactionId"
					component={Estorno}
					exact
				/>
	
				<LogadoRoute
					path="/transacoes/:transactionId"
					component={TransactionsDetails}
					exact
				/>
	
				{/* ADMIN - LOGADO */}
				{AdminRouteWithContext(['/extrato', '/antecipacoes'], location)}
				{userType === 'administrator' && (
					<Switch>
						<AdminRoute exact path="/recebedores" component={Recipients} />
						<AdminRoute
							exact
							path="/recebedores/adicionar-recebedor"
							component={() => (
								<RecipientsProvider>
									<NewRecipient />
								</RecipientsProvider>
							)}
						/>
						<AdminRoute
							exact
							path="/recebedores/:recipientId"
							component={() => (
								<RecipientInfoProvider>
									<RecipientDetails />
								</RecipientInfoProvider>
							)}
						/>
						<AdminRoute
							exact
							path="/recebedores/:recipientId/antecipacoes"
							component={() => (
								<AnticipationProvider>
									<Antecipacoes />
								</AnticipationProvider>
							)}
						/>
						<AdminRoute exact path="/links-pagamento" component={PaymentLinks} />

						<AdminRoute
							exact
							path="/links-pagamento/criar-link-pagamento"
							component={() => (
								<NewPaymentLinksProvider>
									<NewPaymentLink />
								</NewPaymentLinksProvider>
							)}
						/>

						<AdminRoute
							exact
							path="/links-pagamento/:paymentLinkId"
							component={PaymentDetails}
						/>
						<AdminRoute exact path="/clientes" component={Client} />
						{window.location.pathname === '/' &&
							(userType ? <Redirect to="/resumo" /> : <Redirect to="/login" />)}
	
						<Route
							path="*"
							component={() => <NotFound setNotFound={setNotFound} />}
						/>
					</Switch>
				)}
	
				{/* SUBCONTRACTOR  */}
	
				{userType === 'subcontractor' && (
					<Switch>
						<SubContractorRoute
							exact
							path="/transacoes"
							component={Transactions}
						/>
						<SubContractorRoute
							path="/transacoes/estorno/:transactionId"
							component={Estorno}
							exact
						/>
						<SubContractorRoute
							path="/transacoes/:transactionId"
							component={TransactionsDetails}
							exact
						/>
						{window.location.pathname === '/' &&
							(userType ? <Redirect to="/resumo" /> : <Redirect to="/login" />)}
	
						<Route
							path="*"
							component={() => <NotFound setNotFound={setNotFound} />}
						/>
					</Switch>
				)}
	
				{/* contractor admin */}
				{userType === 'contractor' && (
					<Switch>
						<ContratanteRoute exact path="/resumo" component={Dashboard} />
						<ContratanteRoute exact path="/transacoes" component={Transactions} />
						<ContratanteRoute exact path="/clientes" component={Client} />
						<ContratanteRoute exact path="/recebedores" component={Recipients} />
						<ContratanteRoute
							exact
							path="/recebedores/adicionar-recebedor"
							component={() => (
								<RecipientsProvider>
									<NewRecipient />
								</RecipientsProvider>
							)}
						/>
						<ContratanteRoute
							exact
							path="/recebedores/:recipientId"
							component={() => (
								<RecipientInfoProvider>
									<RecipientDetails />
								</RecipientInfoProvider>
							)}
						/>
						<ContratanteRoute
							exact
							path="/recebedores/:recipientId/antecipacoes"
							component={() => (
								<AnticipationProvider>
									<Antecipacoes />
								</AnticipationProvider>
							)}
						/>
							<ContratanteRoute exact path="/links-pagamento" component={PaymentLinks} />

							<ContratanteRoute
								exact
								path="/links-pagamento/criar-link-pagamento"
								component={() => (
									<NewPaymentLinksProvider>
										<NewPaymentLink />
									</NewPaymentLinksProvider>
								)}
							/>

							<ContratanteRoute
								exact
								path="/links-pagamento/:paymentLinkId"
								component={PaymentDetails}
							/>
						{window.location.pathname === '/' &&
							(userType ? <Redirect to="/resumo" /> : <Redirect to="/login" />)}
	
						<Route
							path="*"
							component={() => <NotFound setNotFound={setNotFound} />}
						/>
					</Switch>
				)}
	
				{window.location.pathname === '/' &&
					(userType ? <Redirect to="/resumo" /> : <Redirect to="/login" />)}
	
				<Route
					path="*"
					component={() => <NotFound setNotFound={setNotFound} />}
				/>
			</Switch>
		);
};
export default withRouter(Routes);
