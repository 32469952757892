import { axiosApp } from '.';

const getPendingAnticipations = (idRecebedor, status = 'pending') =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/anticipations?recipientId=${idRecebedor}&status=${status}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

	const getAntecipacoes = (idRecebedor, status = 'pending') => {
		return new Promise((resolve, reject) => {
			axiosApp
				.get(
					`/anticipations?recipientId=${idRecebedor}&status=${status}`
				)
				.then(({ data }) => resolve(data))
				.catch((error) => reject(error));
		});
	};

	const getLimitesAntecipacao = (idRecebedor, dataPagamento, prazo = 'start') => {
		return new Promise((resolve, reject) => {
			axiosApp
				.get(
					`/anticipations/limits?recipientId=${idRecebedor}&paymentDate=${dataPagamento}&timeframe=${prazo}`
				)
				.then(({ data }) => resolve(data))
				.catch((error) => reject(error));
		});
	};

	const postCriarAntecipacao = (body) => {
		return new Promise((resolve, reject) => {
			axiosApp
				.post('/anticipations/bulk_anticipations', body)
				.then((resp) => resolve(resp.data.data))
				.catch((e) => reject(e));
		});
	};

	const postConfirmarAntecipacao = (idRecebedor, idAntecipacao) => {
		return new Promise((resolve, reject) => {
			axiosApp
				.post(
					`/anticipations/${idRecebedor}/bulk_anticipations/${idAntecipacao}/confirm`
				)
				.then((resp) => resolve(resp.data))
				.catch((e) => reject(e));
		});
	};

	const postCancelarAntecipacao = (idRecebedor, idAntecipacao) => {
		return new Promise((resolve, reject) => {
			axiosApp
				.post(
					`/anticipations/${idRecebedor}/bulk_anticipations/${idAntecipacao}/cancel`
				)
				.then((resp) => resolve(resp.data))
				.catch((e) => reject(e));
		});
	};

	const deleteAntecipacao = (idRecebedor, idAntecipacao) => {
		return new Promise((resolve, reject) => {
			axiosApp
				.delete(`/anticipations/${idRecebedor}/bulk_anticipations/${idAntecipacao}`)
				.then((resp) => resolve(resp.data))
				.catch((e) => reject(e));
		});
	};

export {
	getPendingAnticipations,
	getAntecipacoes,
	getLimitesAntecipacao,
	postCriarAntecipacao,
	postConfirmarAntecipacao,
	postCancelarAntecipacao,
	deleteAntecipacao
};
