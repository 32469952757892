/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { add, isWeekend, parse, isValid } from 'date-fns';
import { CardFlag } from '../assets/icons/credit-cards';
import { colors, Icons } from '../assets';

// CPF -------------------------------------->
export const validaCPF = (strCPF) => {
	strCPF = strCPF.replace(/[^\d]+/g, '');
	let soma;
	let resto;
	soma = 0;
	if (strCPF.length < 11) return false;

	// Elimina CPFs inválidos conhecidos
	if (
		strCPF === '00000000000' ||
		strCPF === '11111111111' ||
		strCPF === '22222222222' ||
		strCPF === '33333333333' ||
		strCPF === '44444444444' ||
		strCPF === '55555555555' ||
		strCPF === '66666666666' ||
		strCPF === '77777777777' ||
		strCPF === '88888888888' ||
		strCPF === '99999999999'
	)
		return false;

	for (let i = 1; i <= 9; i++) {
		soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
		resto = (soma * 10) % 11;
	}

	if (resto === 10 || resto === 11) resto = 0;
	if (resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

	soma = 0;
	for (let i = 1; i <= 10; i++)
		soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) resto = 0;
	if (resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
	return true;
};

export function mascaraCPF(cpf) {
	cpf = cpf.replace(/\D/g, '');
	cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
	cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
	cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	// 046.881.090-08
	return cpf;
}

// CNPJ -------------------------------------->
export const validaCNPJ = (cnpj) => {
	cnpj = cnpj.replace(/[^\d]+/g, '');

	if (cnpj.length !== 14) return false;

	// Elimina CNPJs invalidos conhecidos
	if (
		cnpj === '00000000000000' ||
		cnpj === '11111111111111' ||
		cnpj === '22222222222222' ||
		cnpj === '33333333333333' ||
		cnpj === '44444444444444' ||
		cnpj === '55555555555555' ||
		cnpj === '66666666666666' ||
		cnpj === '77777777777777' ||
		cnpj === '88888888888888' ||
		cnpj === '99999999999999'
	)
		return false;

	// Valida DVs
	let tamanho = cnpj.length - 2;
	let numeros = cnpj.substring(0, tamanho);
	const digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(0)) return false;

	tamanho += 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(1)) return false;

	return true;
};

export function mascaraCNPJ(cnpj) {
	cnpj = cnpj.replace(/\D/g, '');
	cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
	cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
	cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
	cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
	return cnpj;
}

export const mascaraTelefone = (v) => {
	v = v.toString();
	v = v.replace(/\D/g, '');
	v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
	v = v.replace(/(\d)(\d{4})$/, '$1-$2');
	return v;
};

export function mascaraData(data) {
	data = data.replace(/\D/g, '');
	data = data.replace(/(\d{2})(\d)/, '$1/$2');
	data = data.replace(/(\d{2})(\d)/, '$1/$2');

	return data;
}

export const formatValor = (n, noCash, percent) => {
	if (n || n === 0) {
		const numero = Number(n).toFixed(2).split('.');
		if (!noCash) {
			numero[0] = `R$ ${numero[0].split(/(?=(?:...)*$)/).join('.')}`;
			return numero.join(',');
		}
		numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
		if (percent) {
			let resp = `${Math.round(n)}`;
			resp = resp.split(/(?=(?:...)*$)/).join('.');
			return `${resp}%`;
		}
		return numero[0];
	}
};

export function formatarParaReais(valor, cifrao) {
	let val;
	let valorFormatado;

	if (typeof valor === 'number') {
		val = valor
			?.toString()
			.replace('.', '')
			.replace(/(\d+)(\d{2})/, '$1,$2');
		Number(val);
	}
	if (typeof valor !== 'number') {
		val = valor?.replace(',', '').replace(/(\d+)(\d{2})/, '$1,$2');
		val = Number(valor);
	} else val = valor;

	if (cifrao) {
		valorFormatado = val?.toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			style: 'currency',
			currency: 'BRL',
		});
	} else {
		valorFormatado = val?.toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	}

	return valorFormatado;
}

// Formatar valor no onChange do input

export function valorMonetario(value) {
	let valor = String(value).replace(/\D/g, '');
	valor = (valor / 100).toFixed(2);
	valor = valor.replace('.', ',');
	valor = valor.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
	valor = valor.replace(/(\d)(\d{3}),/g, '$1.$2,');
	return valor;
}

export const capitalizeFirstLetter = (string) =>
	string?.charAt(0)?.toUpperCase() + string?.slice(1);

export const validateEmail = (email) => {
	const re =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	if (!re.test(email)) return true;
	return false;
};

export const validateUrl = (url) => {
	const re =
		// eslint-disable-next-line no-useless-escape
		/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
	if (!re.test(url)) return true;
	return false;
};

export function maskCep(v) {
	v = v.replace(/\D/g, '');
	v = v.replace(/(\d)(\d{3})$/, '$1-$2');
	return v;
}

export function capitalizeFirstLetterSpace(string) {
	string = string.charAt(0).toUpperCase() + string.slice(1);
	string = string.replaceAll('_', ' ');
	return string;
}

export function justNumbers(string) {
	string = string.replace(/\D/g, '');
	return string;
}

export function removeFirstsNumbers(string) {
	string = string.split('');
	string = string.slice(3, 14);
	string = string.join('');
	return string;
}

export function translateStatus(statusTranslate) {
	const status = {
		authorized: 'Autorizada',
		credit: 'Cartão de crédito',
		chargedback: 'Chargeback',
		paid: 'Pago',
		processing: 'Processando',
		refunded: 'Estornada',
		refused: 'Recusada',
		pending_refund: 'Reembolso pendente',
	};
	return status[statusTranslate];
}

export function translatePaymentMethod(paymentMethod) {
	const payment = {
		credit_card: 'Cartão de crédito',
		debit_card: 'Cartão de débito',
		boleto: 'Boleto',
		pix: 'Pix',
		foreign_credit_card: 'Cartão de crédito estrangeiro',
	};
	return payment[paymentMethod];
}

export function translateRefuseReason(refuseReason) {
	const reason = {
		acquirer: 'Operadora de cartão',
		no_acquirer: 'Sem adquirente configurado',
		acquirer_timeout: 'Tempo de resposta excedido',
		antifraud: 'Antifraude',
		internal_error: 'Erro interno',
	};
	return reason[refuseReason];
}

export function formatAccountTypes(accountType) {
	const accounts = {
		conta_corrente: 'Conta corrente',
		conta_poupanca: 'Conta poupança',
		conta_corrente_conjunta: 'Conta corrente conjunta',
		conta_poupanca_conjunta: 'Conta poupança conjunta',
	};
	return accounts[accountType];
}

export function replaceAccent(string) {
	if (string !== null || string !== undefined || string !== '') {
		const newString = string?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
		return newString;
	}
	return '';
}

export function stringBeforePeriod(string) {
	const newString = string.split('.');
	return newString[0];
}

export function stringAfterPeriod(string) {
	const newString = string.split('.');
	return newString[1];
}
export const padStart = (text, casasPreencher = 2, preenchimento = 0) =>
	String(text).padStart(casasPreencher, preenchimento);

export const formatCardNumber = (firstDigits, lastDigits) => {
	const formatedFirstDigits = firstDigits?.substring(0, 4);
	const formatedFirstDigits2 = firstDigits?.substring(4, 6);
	return `${formatedFirstDigits} ${formatedFirstDigits2}** **** ${lastDigits}`;
};

export const renderFlag = (brand) => {
	if (brand === 'amex') {
		return <CardFlag.Amex />;
	}
	if (brand === 'visa') {
		return <CardFlag.Visa />;
	}
	if (brand === 'mastercard') {
		return <CardFlag.Mastercard />;
	}
	if (brand === 'diners') {
		return <CardFlag.Diners />;
	}
	if (brand === 'elo') {
		return <CardFlag.Elo />;
	}
	if (brand === 'hipercard') {
		return <CardFlag.Hipercard />;
	}
	if (brand === 'jcb') {
		return <CardFlag.Jcb />;
	}
	if (brand === 'aura') {
		return <CardFlag.Aura />;
	}
	if (brand === 'discover') {
		return <CardFlag.Discover />;
	}
	if (brand === 'unknown') {
		return <Icons.CreditCard />;
	}
	return null;
};

export const formatDoc = (doc) => {
	const tamanho = doc.length;
	if (tamanho === 11) {
		return mascaraCPF(doc);
	}
	if (tamanho === 14) {
		return mascaraCNPJ(doc);
	}
	return doc;
};

export function formatNumberDDD(value) {
	let mobile = '';
	if (value.charAt(0) === '+' || value.charAt(0) === '0') {
		// (00) 999999999
		mobile = value
			.replace(/\D/g, '')
			.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
	} else {
		mobile = value.replace(/[^a-zA-Z0-9]/g, '');
		mobile = mobile.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2$3');
	}
	return value;
}

// ----------- Datas ---------------------------

export function dataFormatada(intervaloDatas) {
	if (intervaloDatas?.day) {
		return {
			from: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
			to: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
		};
	}
	const dataInicial = intervaloDatas?.from;
	const dataFinal = intervaloDatas?.to;

	return {
		from: `${dataInicial?.year}/${dataInicial?.month}/${dataInicial?.day}`,
		to: `${dataFinal?.year}/${dataFinal?.month}/${dataFinal?.day}`,
	};
}

export const dataInicialLimitesAntecipacao = (timeStamp = false) => {
	let data = new Date();

	if (
		data.getHours() > 10 ||
		(data.getHours() > 10 && data.getMinutes() > 58)
	) {
		data = add(new Date(), { days: 1 });
	}

	let dayIsWeekend = isWeekend(data);

	while (dayIsWeekend) {
		data = add(data, { days: 1 });
		if (!isWeekend(data)) dayIsWeekend = false;
	}

	const dia = data.getDate();
	const mes = data.getMonth() + 1;
	const ano = data.getFullYear();

	if (timeStamp) return data.getTime();

	return {
		day: dia,
		month: mes,
		year: ano,
	};
};

// a função a baixo, deve receber a data neste formato: 2022-09-26T14:06:48.659Z
export function formatDate(date) {
	const year = date.split('-')[0];
	const mounth = date.split('-')[1];
	const day = date.split('-')[2].slice(0, 2);
	const dateFormated = `${day}/${mounth}/${year}`;
	return dateFormated;
}

export function formatDateAuth(date) {
	const dateFormated = new Date(date);
	const Hours = String(dateFormated)?.split('-')[0].slice(16, 18);

	return Number(Hours);
}

// ------------------

export function formatNumberUnits(val) {
	if (val >= 0 && val < 1000) return val;
	if (val < 1000000) return `${val / 1000}mil`;
	if (val < 1000000000) return `${val / 1000000}M`;
}

export function statusColor(type) {
	if (type === 'chargedback') {
		return colors.graphs.yellow;
	}
	if (type === 'paid') {
		return colors.graphs.green;
	}
	if (type === 'refused') {
		return colors.graphs.red;
	}
	if (type === 'authorized') {
		return colors.graphs.cyan;
	}
	if (type === 'refunded') {
		return colors.graphs.pink;
	}
	if (type === 'pending_refund') {
		return colors.graphs.orange;
	}
	if (type === 'waiting_payment') {
		return colors.graphs.purple;
	}
	if (type === 'processing') {
		return colors.graphs.blue;
	}
	if (type === 'pending_review') {
		return colors.white;
	}
	return colors.graphs.gray;
}

// ------------ Exportação/download ------------------------

export const settingsExportExcel = {
	fileName: 'Extrato',
	extraLength: 3,
	writeOptions: {},
};

// ------------ Páginas do submenu ------------------------
export const pageTitleSidemenu = (path) => {
	let title = path.substring(1).split('/')[0];

	switch (title) {
		case 'resumo':
			title = 'Resumo';
			break;
		case 'antecipacoes':
			title = 'Extrato';
			break;
		case 'extrato':
			title = 'Extrato';
			break;
		case 'transacoes':
			title = 'Transações';
			break;
		case 'recebedores':
			title = 'Recebedores';
			break;
		case 'taxas':
			title = 'Taxas';
			break;
		case 'links-pagamento':
			title = 'Links de pagamento';
			break;
		case 'contratante':
		case 'contratantes':
			title = 'Contratantes';
			break;
		case 'acessos':
			title = 'Acessos';
			break;
		case 'configuracoes':
			title = 'Configurações';
			break;
		case 'clientes':
			title = 'Clientes';
			break;
		case 'logs':
			title = 'Logs';
			break;
		// Quando logamos o pathname pode estar ainda com o estado antigo /login
		case 'login':
			title = 'Resumo';
			break;
		default:
			return;
	}

	return title;
};

export const scrollTop = (num = 0) => {
	document.body.scrollTop = num;
	document.documentElement.scrollTop = num;
};

export const optionsTipoConta = [
	{
		value: 'conta_corrente',
		label: 'Conta corrente',
	},
	{
		value: 'conta_corrente_conjunta',
		label: 'Conta corrente conjunta',
	},
	{
		value: 'conta_poupanca',
		label: 'Conta poupança',
	},
	{
		value: 'conta_poupanca_conjunta',
		label: 'Conta poupança conjunta',
	},
];

export const optionsWeekly = [
	{
		label: 'Segunda-feira',
		value: '1',
	},
	{
		label: 'Terça-feira',
		value: '2',
	},
	{
		label: 'Quarta-feira',
		value: '3',
	},
	{
		label: 'Quinta-feira',
		value: '4',
	},
	{
		label: 'Sexta-feira',
		value: '5',
	},
];
export const optionsItemsPage = (isBeiraRioContractor) =>
	isBeiraRioContractor
		? [
				{
					label: '5 itens por página',
					value: 5,
				},
				{
					label: '10 itens por página',
					value: 10,
				},
				{
					label: '15 itens por página',
					value: 15,
				},
				{
					label: '20 itens por página',
					value: 20,
				},
		  ]
		: [
				{
					label: '15 itens por página',
					value: 15,
				},
				{
					label: '30 itens por página',
					value: 30,
				},
				{
					label: '60 itens por página',
					value: 60,
				},
				{
					label: '100 itens por página',
					value: 100,
				},
		  ];

export const optionsMonthly = () => {
	const options = [];
	for (let i = 1; i <= 31; i++) {
		options.push({
			label: `${i}`,
			value: `${i}`,
		});
	}
	return options;
};

export const setLocalStorageObjectFormat = (key, data) => {
	return localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorageObjectFormat = (key) => {
	return JSON.parse(localStorage.getItem(key));
};

export * from './calendar-date-config';
