import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: ${(props) => (props.width ? props.width : '100%')};
	min-width: ${(props) => (props.minWidth ? props.minWidth : '95px')};
	padding: 18px 27px 19px 33px;
	justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
	background-color: ${colors.darkGray};
	min-height: ${(props) => (props.minHeight ? props.minHeight : '95px')};
	max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'none')};
	border: ${(props) => (props.border ? props.border : 'none')};
	border-radius: ${(props) =>
		props.borderRadius ? props.borderRadius : '15px'};
	border-color: ${({ isInvalid, borderColor }) =>
		isInvalid ? colors.primaryRed : borderColor};
	color: ${({ isInvalid, tittleError }) =>
		tittleError
			? (isInvalid.phone ? colors.red : colors.white) ||
			  (isInvalid.name ? colors.red : colors.white) ||
			  (isInvalid.email ? colors.red : colors.white)
			: colors.white};
`;
export const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	text-overflow: clip;
	color: ${({ isInvalid, tittleError }) =>
		tittleError
			? (isInvalid.phone !== true ? colors.red : colors.white) ||
			  (isInvalid.name !== true ? colors.red : colors.white) ||
			  (isInvalid.email !== true ? colors.red : colors.white)
			: colors.white};
`;

export const Title = styled.label`
	flex: 1;
	padding-bottom: 5px;
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
	text-align: ${({ titleTextAlign }) => titleTextAlign || 'left'};
	color: ${({ isInvalid }) => (isInvalid === true ? colors.red : colors.white)};
`;

export const Infos = styled.div`
	display: flex;
	flex: 2;
	padding: 0 24px 24px 24px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	h2 {
		color: #454550;
		font-weight: 600;
		margin: 0;
	}

	div {
		display: flex;
		align-items: center;
	}
`;
