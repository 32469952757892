import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks';
import { ExtractProvider, AnticipationProvider } from '../contexts';
import { Extract, Antecipacoes } from '../pages';

export const DeslogadoRoute = ({ component: Component, ...rest }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;

	return (
		<Route
			{...rest}
			render={({ location }) =>
				!userType ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: '/resumo',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export const LogadoRoute = ({ component: Component, ...rest }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;

	return (
		<Route
			{...rest}
			render={({ location }) =>
				userType ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export const AdminRoute = ({ component: Component, ...rest }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;

	return (
		<Route
			exact
			{...rest}
			render={({ location }) =>
				userType === 'administrator' ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: userType ? '/resumo' : '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export const AdminRouteWithContext = (paths, location) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;
	const path = location?.pathname;

	const matchPath = (rota) => {
		if (path === `/${rota}` || path === `/${rota}/`) return true;
		return false;
	};

	if (matchPath('extrato') || matchPath('antecipacoes')) {
		return (
			<Route
				exact
				path={paths}
				render={() => {
					if (
						userType === 'administrator' ||
						userType === 'contractor' ||
						userType === 'subcontractor'
					) {
						return (
							<ExtractProvider>
								{matchPath('extrato') ? (
									<Extract />
								) : (
									matchPath('antecipacoes') && (
										<AnticipationProvider>
											<Antecipacoes />
										</AnticipationProvider>
									)
								)}
							</ExtractProvider>
						);
					}

					return (
						<Redirect
							to={{
								pathname: '/resumo',
								state: { from: location },
							}}
						/>
					);
				}}
			/>
		);
	}

	return;
};

export const ContratanteRoute = ({ component: Component, ...rest }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;

	return (
		<Route
			{...rest}
			render={({ location }) =>
				userType === 'contractor' ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: userType ? '/resumo' : '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export const SubContractorRoute = ({ component: Component, ...rest }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;

	return (
		<Route
			{...rest}
			render={({ location }) =>
				userType === 'subcontractor' ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: userType ? '/resumo' : '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};
