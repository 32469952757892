import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-grow: 1;
	background: ${colors.darkGray};
	border-radius: 15px;
	width: 100%;
`;

export const CustomToolbar = styled.div`
	display: flex;
	width: 100%;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	padding: 1.25rem 0rem;

	@media (max-width: 1500px) {
		padding: 1.25rem 1.25rem;
	}
	@media (max-width: 1050px) {
		padding: 1.25rem 1.75rem;
	}

	.button-custom {
		margin-right: 0.9375rem;
		width: auto;
		height: 3.125rem;
		white-space: nowrap;
	}
`;

export const InfoTransactions = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: ${colors.offWhite};
	font-size: 1.25rem;
	margin-right: auto;

	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	p {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: normal;
		flex-wrap: nowrap;
		word-wrap: normal;
	}
	h4 {
		font-weight: 600;
		font-style: normal;
	}
	@media all and (max-width: 820px) {
		font-size: 1rem;
	}
`;

export const SelectDiv = styled.div`
	border-radius: 15px;
`;

export const Table = styled.table`
	width: 100%;

	.row-table:nth-child(even) {
		background: ${colors.rowTable};

		:hover {
			cursor: pointer;
			opacity: 0.75;
		}
	}
	.row-table:nth-child(odd) {
		background: ${colors.gridBlack};
		:hover {
			cursor: pointer;
			opacity: 0.75;
		}
	}

	//Campo de carregamento
	.MuiDataGrid-window {
		background: ${colors.darkGray} !important;
		overflow-x: hidden !important;
	}

	/* meu estilos abaixo */

	//Esconderheader de exportação//
	.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall {
		display: none;
	}
	.MuiDataGrid-columnsContainer {
		border-radius: 0.313rem 0.313rem 0px 0px;
	}
	// Borda da tabela
	.MuiDataGrid-root {
		border: 0;
	}
	.MuiDataGrid-columnHeader {
		background-color: ${colors.background};
		color: ${colors.primaryBlue};
	}

	.MuiDataGrid-columnHeaders {
		border-bottom: 0;
	}

	.MuiDataGrid-row.Mui-odd {
		cursor: pointer;
		background: ${colors.rowTable};
	}
	.MuiDataGrid-row.Mui-even {
		background: ${colors.darkGray};
		cursor: pointer;
	}
	// tira borda branca entre o tablehead e tablebody
	.MuiDataGrid-root .MuiDataGrid-columnsContainer {
		border-bottom: 0px solid;
	}
	/* 	.MuiDataGrid-columnsContainer {
		border-left: 1px solid ${colors.darkGray};
	} */

	.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 1rem;
		line-height: 2rem;
		letter-spacing: 3px;
	}

	// borda que separa os titulos das colunas
	.MuiDataGrid-columnHeader {
		border-left: 1px solid rgba(81, 85, 87, 0.5);
		:first-child {
			border-left: transparent;
		}
	}

	.MuiDataGrid-main {
		border: 1px solid rgba(81, 85, 87, 0.5);
		box-sizing: border-box;
		border-radius: 0.313rem;
		overflow-x: hidden !important;
	}

	.MuiDataGrid-iconSeparator {
		display: none;
	}

	.MuiDataGrid-sortIcon {
		color: ${colors.primaryBlue};
	}

	.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
	.MuiDataGrid-root .MuiDataGrid-cell:focus {
		outline: none;
	}

	.MuiDataGrid-root .MuiDataGrid-cell {
		// border-bottom-color: transparent;
		border-bottom: 0;
	}

	.MuiDataGrid-cell {
		color: ${colors.white};
		border-left: 1px solid rgba(81, 85, 87, 0.5);
		:first-child {
			border-left: transparent;
		}
	}
`;

export const Data = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	p {
		font-size: 1rem;
		font-weight: 400;
		line-height: 32px;
		margin-left: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	p.data {
		line-height: 0.9375rem;
		margin-top: 0;
		padding: 0;
	}

	p.bold {
		font-weight: 600;
	}

	svg.seta-curva-icon {
		height: 1.25rem;
		width: 1.25rem;
	}

	span {
		height: 0.9375rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 5px;
	width: 100%;
	p.status {
		font-size: 1rem;
		font-weight: 400;
		line-height: 32px;
		margin-left: 5px;
		color: ${({ value }) => {
			if (value === 'paid') {
				return colors.green;
			}
			if (value === 'authorized') {
				return colors.primaryBlue;
			}
			if (value === 'refused') {
				return colors.primaryRed;
			}
			if (value === 'refunded') {
				return colors.yellow;
			}
			return colors.white;
		}};
	}
	span {
		transform: translateY(1.25px);
	}
`;

export const LoadingContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 5px 5px;
	height: 100%;
	width: 100%;

	.loading-rectangle {
		height: 55px;
	}
`;

export const Pagination = styled.div`
	width: auto;
	display: flex;
	justify-content: flex-end;
`;

export const SwitchContainer = styled.div`
	width: auto;
	padding-left: 1rem;
	display: flex;
	justify-content: center;
`;

export const Options = styled.div`
	width: 10rem;

	ul {
		list-style: none;
	}

	button {
		background: none;
		padding: 0.5rem 1rem;
		font-size: 0.875rem;
		width: 100%;

		:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}
`;
